import React, { Suspense, Fragment, useState } from "react";
import { TopBarLoader } from "../../components/Loaders";
import PrivateRoute from "../../navigation/PrivateRoute";
import routes from "../../navigation/routes";
import AppHeader from "./AppHeader";
import { useLocation, useHistory } from "react-router-dom";
import { Avatar, Dialog } from "evergreen-ui";
import cookiesConfig from "../../utils/cookies.config";

import logo from "../../assets/logo.jpg";
import GetUserData from "../../utils/MetaData";

export default function Layout() {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [showLogout, setShowLogout] = useState();

  return (
    <Fragment>
      {/* <AppHeader /> */}

      <Dialog
        onConfirm={() => {
          // setShowLogout(false);
          push("/login");
          cookiesConfig.clearCipher();
        }}
        hasHeader={false}
        isShown={showLogout}
        onCloseComplete={() => {
          setShowLogout(false);
        }}
      >
        <h1>Do you want to logout</h1>
      </Dialog>
      <div class="h-screen bg-white overflow-hidden flex">
        {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
        <div
          class="fixed inset-0 z-40 flex md:hidden"
          role="dialog"
          aria-modal="true"
        >
          {/* <!--
      Off-canvas menu overlay, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
          <div
            class="fixed inset-0 bg-gray-600 bg-opacity-75"
            aria-hidden="true"
          ></div>

          {/* <!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    --> */}
          <div class="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
            {/* <!--
        Close button, show/hide based on off-canvas menu state.

        Entering: "ease-in-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in-out duration-300"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
            <div class="absolute top-0 right-0 -mr-14 p-1">
              <button class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:bg-gray-600">
                {/* <!-- Heroicon name: outline/x --> */}
                <svg
                  class="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span class="sr-only">Close sidebar</span>
              </button>
            </div>

            <div class="flex-shrink-0 px-4 flex items-center">
              <img
                class="h-8 w-auto"
                src="https://tailwindui.com/img/logos/easywire-logo-purple-600-mark-gray-900-text.svg"
                alt="Easywire"
              />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="h-full flex flex-col">
                <div class="space-y-1">
                  {/* <!-- Current: "bg-purple-50 border-purple-600 text-purple-600", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900" --> */}
                  <a
                    href="#"
                    class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"
                  >
                    {/* <!-- Heroicon name: outline/home --> */}
                    <svg
                      class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                    Dashboard
                  </a>

                  <a
                    href="#"
                    class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"
                  >
                    {/* <!-- Heroicon name: outline/briefcase --> */}
                    <svg
                      class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    Jobs
                  </a>

                  <a
                    href="#"
                    class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"
                  >
                    {/* <!-- Heroicon name: outline/document-search --> */}
                    <svg
                      class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                      />
                    </svg>
                    Applications
                  </a>

                  <a
                    href="#"
                    class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"
                  >
                    {/* <!-- Heroicon name: outline/chat --> */}
                    <svg
                      class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                    Messages
                  </a>

                  <a
                    href="#"
                    class="border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"
                  >
                    {/* <!-- Heroicon name: outline/users --> */}
                    <svg
                      class="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                    Team
                  </a>
                </div>
                <div class="mt-auto pt-10 space-y-1">
                  <a
                    href="#"
                    class="group border-l-4 border-transparent py-2 px-3 flex items-center text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                  >
                    {/* <!-- Heroicon name: outline/question-mark-circle --> */}
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Help
                  </a>

                  <a
                    href="#"
                    class="group border-l-4 border-transparent py-2 px-3 flex items-center text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                  >
                    {/* <!-- Heroicon name: outline/cog --> */}
                    <svg
                      class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Logout
                  </a>
                </div>
              </nav>
            </div>
          </div>

          <div class="flex-shrink-0 w-14" aria-hidden="true">
            {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
          </div>
        </div>

        {/* <!-- Static sidebar for desktop --> */}
        <div class="hidden  md:flex md:flex-shrink-0">
          <div class="w-64 flex flex-col">
            {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
            <nav class="bg-red-900 m-2 border rounded-lg border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
              <div class="mx-auto ">
                <img class="h-20 w-auto" src={logo} alt="ghana" />
              </div>

              <div class="flex-shrink-0 px-4 mx-auto flex items-center">
                <h1 className="mt-1 text-2xl font-extrabold text-white">
                  F&O Tracker
                </h1>
                {/* <p>{getUserData.name}</p> */}
              </div>
              <div class="flex-grow mt-5 flex flex-col">
                <div class="flex-1 space-y-1">
                  {/* <!-- Current: "bg-purple-50 border-purple-600 text-purple-600", Default: "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50" --> */}
                  <a
                    href="#/dashboard"
                    class={`border-transparent ${
                      pathname === "/dashboard"
                        ? "bg-gray-50  border-l-4 border-gray-500"
                        : ""
                    } ${
                      pathname === "/dashboard" ? "text-gray-600" : "text-white"
                    } hover:text-gray-900 hover:bg-red-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    {/* <!--
                Heroicon name: outline/home

                Current: "text-purple-500", Default: "text-gray-400 group-hover:text-gray-500"
              --> */}
                    <svg
                      class={`${
                        pathname === "/dashboard"
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                    Dashboard
                  </a>

                  <a
                    href="#/customers"
                    class={`border-transparent ${
                      pathname.includes("customers")
                        ? "bg-gray-50  border-l-4 border-gray-500"
                        : ""
                    } ${
                      pathname.includes("customers") ? "gray" : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    {/* <!-- Heroicon name: outline/briefcase --> */}
                    <svg
                      class={`${
                        pathname.includes("customers")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    Customers
                  </a>

                  <a
                    href="#/drivers"
                    class={`border-transparent ${
                      pathname.includes("drivers")
                        ? "bg-red-50  border-l-4 border-gray-500"
                        : ""
                    } ${
                      pathname.includes("drivers")
                        ? "text-gray-700"
                        : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    {/* <!-- Heroicon name: outline/users --> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`${
                        pathname.includes("drivers")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                      />
                    </svg>
                    Drivers
                  </a>

                  <a
                    href="#/vehicles"
                    class={`border-transparent ${
                      pathname.includes("vehicles")
                        ? "bg-red-50  border-l-4 border-gray-600"
                        : ""
                    } ${
                      pathname.includes("vehicles")
                        ? "text-gray-600"
                        : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`${
                        pathname.includes("vehicles")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        d="M8.00537 13C8.00537 13.5523 7.55766 14 7.00537 14C6.45309 14 6.00537 13.5523 6.00537 13C6.00537 12.4477 6.45309 12 7.00537 12C7.55766 12 8.00537 12.4477 8.00537 13Z"
                        fill="gray"
                      />
                      <path
                        d="M16.9998 14C17.552 14 17.9998 13.5523 17.9998 13C17.9998 12.4477 17.552 12 16.9998 12C16.4475 12 15.9998 12.4477 15.9998 13C15.9998 13.5523 16.4475 14 16.9998 14Z"
                        fill="gray"
                      />
                      <path
                        d="M9.48438 14.75C9.48438 14.3358 9.82016 14 10.2344 14H13.7188C14.133 14 14.4688 14.3358 14.4688 14.75C14.4688 15.1642 14.133 15.5 13.7188 15.5H10.2344C9.82016 15.5 9.48438 15.1642 9.48438 14.75Z"
                        fill="gray"
                      />
                      <path
                        d="M9.26562 1.99219C8.85141 1.99219 8.51562 2.32797 8.51562 2.74219V4.5H7.85159C6.42162 4.5 5.15966 5.43465 4.74277 6.8025L4.68258 7H3.75C3.33579 7 3 7.33579 3 7.75C3 8.16421 3.33579 8.5 3.75 8.5H4.28302L4.08121 9.327C3.43291 9.72189 3 10.4354 3 11.25V19.75C3 20.7165 3.7835 21.5 4.75 21.5H6.25C7.2165 21.5 8 20.7165 8 19.75V18.5H16V19.75C16 20.7165 16.7835 21.5 17.75 21.5H19.25C20.2165 21.5 21 20.7165 21 19.75V11.25C21 10.4347 20.5663 9.72063 19.9171 9.32596L19.7106 8.5H20.25C20.6642 8.5 21 8.16421 21 7.75C21 7.33579 20.6642 7 20.25 7H19.308L19.2529 6.81612C18.8404 5.44142 17.5751 4.5 16.1399 4.5H15.5V2.74219C15.5 2.32797 15.1642 1.99219 14.75 1.99219H9.26562ZM14 4.5H10.0156V3.49219H14V4.5ZM7.85159 6H16.1399C16.9127 6 17.5941 6.50692 17.8161 7.24714L18.0266 7.94881L18.2894 9H5.70503L5.96165 7.94839L6.17761 7.23981C6.40209 6.50327 7.08161 6 7.85159 6ZM4.5 17V11.25C4.5 10.8358 4.83579 10.5 5.25 10.5H18.75C19.1642 10.5 19.5 10.8358 19.5 11.25V17H4.5ZM4.5 19.75V18.5H6.5V19.75C6.5 19.8881 6.38807 20 6.25 20H4.75C4.61193 20 4.5 19.8881 4.5 19.75ZM19.5 18.5V19.75C19.5 19.8881 19.3881 20 19.25 20H17.75C17.6119 20 17.5 19.8881 17.5 19.75V18.5H19.5Z"
                        fill="gray"
                      />
                    </svg>
                    Vehicles
                  </a>
                  <a
                    href="#/service-types"
                    class={`border-transparent ${
                      pathname.includes("service-types")
                        ? "bg-red-50  border-l-4 border-gray-600"
                        : ""
                    } ${
                      pathname.includes("service-types")
                        ? "text-gray-600"
                        : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`${
                        pathname.includes("service-types")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Service Types
                  </a>

                  <a
                    href="#/requests/pending"
                    class={`border-transparent ${
                      pathname.includes("requests")
                        ? "bg-red-50  border-l-4 border-gray-600"
                        : ""
                    } ${
                      pathname.includes("requests")
                        ? "text-gray-600"
                        : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`${
                        pathname.includes("requests")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                      />
                    </svg>
                    Requests
                  </a>

                  <a
                    href="#/expenditure"
                    class={`border-transparent ${
                      pathname.includes("expenditure")
                        ? "bg-red-50  border-l-4 border-gray-600"
                        : ""
                    } ${
                      pathname.includes("expenditure")
                        ? "text-gray-600"
                        : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`${
                        pathname.includes("expenditure")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                      />
                    </svg>
                    Expenditure
                  </a>

                  <a
                    href="#/invoices"
                    class={`border-transparent ${
                      pathname.includes("invoices")
                        ? "bg-red-50  border-l-4 border-gray-600"
                        : ""
                    } ${
                      pathname.includes("invoices")
                        ? "text-gray-600"
                        : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`${
                        pathname.includes("invoices")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    Invoices
                  </a>

                  <p className="text-gray-300 font-medium m-5">
                    Business Intelligence
                  </p>
                  <a
                    href="#/reports/request"
                    class={`border-transparent ${
                      pathname.includes("reports")
                        ? "bg-red-50  border-l-4 border-gray-500"
                        : ""
                    } ${
                      pathname.includes("reports") ? "gray" : "text-white"
                    } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                    {/* <!-- Heroicon name: outline/cog --> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`${
                        pathname.includes("reports")
                          ? "text-gray-500"
                          : "text-white"
                      } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                      <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                    </svg>
                    Analytics & Reports
                  </a>
                  {/* <a
                    href="#/kpi"
                    class={`border-transparent ${
                      pathname === "/kpi"
                        ? "bg-gray-50  border-l-4 border-gray-600"
                        : ""
                    } text-${
                      pathname === "/kpi" ? "gray" : "gray"
                    }-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                  >
                   
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class={`text-${
                        pathname === "/kpi" ? "gray" : "gray"
                      }-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                      />
                    </svg>
                    KPI
                  </a> */}
                </div>
              </div>
              <div class="flex-shrink-0 block w-full">
                <a
                  href="#/users"
                  class={`border-transparent ${
                    pathname.includes("users")
                      ? "bg-red-50  border-l-4 border-gray-400"
                      : ""
                  } ${
                    pathname.includes("users") ? "text-gray-600" : "text-white"
                  } hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                >
                  {/* <!-- Heroicon name: outline/users --> */}
                  <svg
                    class={`${
                      pathname.includes("users")
                        ? "text-gray-500"
                        : "text-white"
                    } group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  Users
                </a>
                {/* <a
                  href="#/help"
                  class={`border-transparent ${
                    pathname === "/help"
                      ? "bg-gray-50  border-l-4 border-gray-600"
                      : ""
                  } text-${
                    pathname === "/help" ? "gray" : "gray"
                  }-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                >
  
                  <svg
                    class={`text-${
                      pathname === "/help" ? "gray" : "gray"
                    }-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  Help
                </a> */}
                {/* <a
                  href="#/settings"
                  class={`border-transparent ${
                    pathname === "/settings"
                      ? "bg-gray-50  border-l-4 border-gray-600"
                      : ""
                  } text-${
                    pathname === "/settings" ? "gray" : "gray"
                  }-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium`}
                >
                 
                  <svg
                    class={`text-${
                      pathname === "/settings" ? "gray" : "gray"
                    }-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  Settings
                </a> */}
                <span
                  onClick={() => {
                    setShowLogout(true);
                  }}
                  // href="#"
                  class="group border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-white hover:text-gray-900 hover:bg-gray-50"
                >
                  {/* <!-- Heroicon name: outline/cog --> */}
                  <svg
                    class="text-white group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  Logout
                </span>
                <div>
                  <button
                    type="button"
                    class="group w-full   px-3.5 py-2 text-sm text-left font-medium text-gray-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                    id="options-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span class="flex w-full justify-between items-center">
                      <span class="flex min-w-0 items-center justify-between space-x-3">
                        <Avatar size={40} name={GetUserData()?.name} />
                        <span class="flex-1 flex flex-col min-w-0">
                          <span class="text-white text-sm font-medium truncate group-hover:text-gray-900">
                            {GetUserData()?.name}
                          </span>
                          <span class="text-white text-sm truncate group-hover:text-gray-900">
                            {GetUserData()?.email}
                          </span>
                        </span>
                      </span>
                      {/* <!-- Heroicon name: solid/selector --> */}
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </div>

        {/* <!-- Content area --> */}
        <div class="flex-1 flex flex-col">
          <main class="flex-1 overflow-y-auto focus:outline-none">
            <div class="relative   md:px-8 pt-2 xl:px-0">
              <main>
                <Suspense fallback={TopBarLoader()}>
                  {routes.map((screen, i) => (
                    <PrivateRoute
                      name={screen.name}
                      component={screen.component}
                      exact={screen.exact}
                      path={screen.path}
                      key={i}
                    />
                  ))}
                </Suspense>
              </main>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
