import React, { Fragment, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../utils/cookies.config";

const PrivateRoute = ({ component: Component, path, name, exact }) => {
  useEffect(() => {
    let token = Auth.getToken();
    if (!token) {
      window.location.href = "/login";
    }
  }, []);

  return (
    <Fragment>
      {/* {!auth && <Redirect to={{ pathname: "/login" }} />} */}
      {/* <Redirect exact from={"#/"} to={"/dashboard"} /> */}
      <Route
        path={path}
        exact={exact}
        // name={name}
        render={(props) => {
          return <Component />;
        }}
      />
    </Fragment>
  );
};

export default PrivateRoute;
