import React, { Fragment } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { Grid, Circle, Orbitals } from "react-spinners-css";

TopBarProgress.config({
  barColors: {
    1: "red",
  },
  shadowBlur: 5,
});

export function TopBarLoader() {
  return (
    <React.Fragment>
      <TopBarProgress />
    </React.Fragment>
  );
}

export function PageLoader() {
  return (
    <Fragment>
      <div className={"w-full  flex justify-center items-center"}>
        <Orbitals color="red" size={100} />
      </div>
    </Fragment>
  );
}
