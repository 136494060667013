const { lazy } = require("react");

const Dashboard = lazy(() => import("../screens/Dashboard/index"));
const Vehicles = lazy(() => import("../screens/Vehicle/index"));
const VehicleOwner = lazy(() => import("../screens/VehicleOwners/index"));
const Customers = lazy(() => import("../screens/Customers"));
const Users = lazy(() => import("../screens/Users/Screens/Users"));
const Messages = lazy(() => import("../screens/Messages/index"));
const Projects = lazy(() => import("../screens/Projects/index"));
const Settings = lazy(() => import("../screens/Settings/index"));
const ServiceType = lazy(() => import("../screens/ServiceTypes"));
const Expenditure = lazy(() => import("../screens/Expenditure"));
const Invoices = lazy(() => import("../screens/Invoices"));
const Drivers = lazy(() => import("../screens/Drivers/index"));
const Requests = lazy(() => import("../screens/Requests/index"));
const DriverDetails = lazy(() => import("../screens/Drivers/DriverDetails"));
const Reports = lazy(() => import("../screens/BusinessIntelligence/index"));

const CustomerDetails = lazy(() =>
  import("../screens/Customers/CustomersDetails")
);

const routes = [
  {
    path: "/dashboard",
    exact: false,
    component: Dashboard,
    name: "Dashboard",
  },
  {
    path: "/customers",
    exact: true,
    component: Customers,
    name: "Customers",
  },
  {
    path: "/customers/details/:id",
    exact: true,
    component: CustomerDetails,
    name: "CustomerDetails",
  },
  {
    path: "/users",
    exact: false,
    component: Users,
    name: "Users",
  },
  {
    path: "/vehicles",
    exact: false,
    component: Vehicles,
    name: "Vehicles",
  },
  {
    path: "/requests",
    exact: false,
    component: Requests,
    name: "Requests",
  },
  {
    path: "/messages",
    exact: false,
    component: Messages,
    name: "Messages",
  },
  {
    path: "/projects",
    exact: false,
    component: Projects,
    name: "Projects",
  },
  {
    path: "/settings",
    exact: false,
    component: Settings,
    name: "Settings",
  },
  {
    path: "/service-types",
    exact: false,
    component: ServiceType,
    name: "ServiceType",
  },
  {
    path: "/expenditure",
    exact: false,
    component: Expenditure,
    name: "Expenditure",
  },
  {
    path: "/invoices",
    exact: false,
    component: Invoices,
    name: "Invoices",
  },
  {
    path: "/drivers",
    exact: true,
    component: Drivers,
    name: "Drivers",
  },
  {
    path: "/drivers/details/:id",
    exact: true,
    component: DriverDetails,
    name: "DriverDetails",
  },
  {
    path: "/reports",
    exact: false,
    component: Reports,
    name: "Reports",
  },
];

export default routes;
