import Cookies from "js-cookie";

export const BASE_URL2 =
  "https://fo-tracker-api.onrender.com";

class Auth {
  getToken() {
    return sessionStorage.getItem("token") || null;
  }

  setCipher(token) {
    sessionStorage.setItem("token", token);
    // Cookies.set("/t", token);
  }

  clearCipher() {
    Cookies.remove("/t");
    sessionStorage.clear();
  }
}

export default new Auth();
