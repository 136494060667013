import { toaster } from "evergreen-ui";


export default function GetUserData() {
  try {
    const user_session_string = sessionStorage.getItem("user");
    const nUserData = JSON.parse(user_session_string);
    console.log("User", nUserData);
    // if (nUserData === null) {
    //   GetUserData();
    // }

    return nUserData;
  } catch (e) {
    console.log(e);
  }
}
