import React from "react";
import { Ring } from "react-spinners-css";
export default function Button(props) {
  return (
    <button
      type="button"
      class={` ${
        props?.fill ? "w-full" : "w-200"
      }  inline-flex flex justify-center items-center px-4 py-2 border border-transparent mt-5 mb-2 shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none `}
      {...props}
    >
      {props?.loading ? <Ring size={25} color="white" /> : props?.title}
    </button>
  );
}
