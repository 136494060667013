import React, { Fragment } from "react";
import Auth from "../../utils/cookies.config";
import { useHistory } from "react-router-dom";
import App from "../../utils/cookies.config";
import useRequest from "../../utils/Request";
import { toaster } from "evergreen-ui";
import Button from "../../components/Button";
import logo from "../../assets/logo.jpg";

export default function Login() {
  const { push } = useHistory();

  const [loginMerchant, { loading }] = useRequest(
    { path: "/auth/login", method: "POST" },
    {
      onCompleted: (d) => {
        toaster.success("Logged in successfully");
        App.setCipher(d.data);
        getUserDetails();
        push("/dashboard");
      },
      onError: (d) => {
        toaster.danger(d?.response?.data || "Something went wrong");
      },
    }
  );

  const [getUserDetails, {}] = useRequest(
    { path: "/user/me" },
    {
      onCompleted: (d) => {
        sessionStorage.setItem("user", JSON.stringify(d?.data));
      },
      onError: (d) => {
        toaster.danger(d?.response?.data || "Something went wrong");
      },
    }
  );

  return (
    <Fragment>
      <div class="min-h-screen bg-white flex">
        <div class="flex-1 flex  flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div class="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div class="mx-auto ">
                <img class="h-20 w-auto" src={logo} alt="ghana" />
              </div>
              <h1 className="mt-6 text-4xl font-extrabold text-gray-900">
                F&O <h1 className="text-red-500">Tracker </h1>
              </h1>

              <h2 class="mt-6 text-3xl font-extrabold text-gray-600">
                Operations Dashboard
              </h2>
            </div>

            <div class="mt-8">
              <div class="mt-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    const payload = {
                      username: e.target?.email.value,
                      password: e?.target?.password?.value,
                    };

                    loginMerchant(payload);
                  }}
                  class="space-y-6"
                >
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Email Address / Phone Number
                    </label>
                    <div class="mt-1">
                      <input
                        id="email"
                        name="email"
                        required
                        class="appearance-none block w-full px-3 py-2 border border-gray-300  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div class="space-y-1">
                    <label
                      for="password"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div class="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autocomplete="current-password"
                        required
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div class="flex items-center justify-between">
                    <div class="flex items-center">
                      <input
                        id="remember_me"
                        name="remember_me"
                        type="checkbox"
                        class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label
                        for="remember_me"
                        class="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div class="text-sm">
                      <a
                        href="/"
                        class="font-medium text-gray-600 hover:text-indigo-500"
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <Button
                      loading={loading}
                      type="submit"
                      fill
                      title="Sign In"
                    />
                    {/* <button
                      type="submit"
                      class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {loading ? "Loading..." : "Sign in"}
                    </button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden lg:block relative w-0 flex-1">
          <img
            class="absolute  inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1549203123-9c4fb68af040?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1774&q=80"
            alt=""
          />
        </div>
      </div>
    </Fragment>
  );
};
